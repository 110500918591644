.Book {
  text-align: center;
  margin: auto;
  width: 160px;
  box-shadow: 0 2px 4px 1px #ccc;
  background-color: white;
  position: relative;
  cursor: pointer;
  cursor: hand;
}

.Book:hover > .book-overlay {
  width: 160px;
  height: 245px;
  position: absolute;
  background-color: #000;
  opacity: 0.2;
}

.link {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.Book > .link {
  visibility: hidden;
}


span.download-link {
  background-image: url('/ic_file_download_black_48px.svg');
  width: 100px;
  height: 100px;
  background-size: 100px;
  display: block;
}

span.description-link {
  background-image: url('/ic_description_48px.svg');
  width: 100px;
  height: 100px;
  background-size: 100px;
  display: block;
}

.Book.selected {
  cursor: default;
}

.Book.selected > img {
  filter: grayscale(100%) blur(1px) opacity(.5);
}

.Book.selected > .link {
  visibility: visible;
}

div.book-details {
  padding: 5px;
  text-align: left;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: 300;
}

span.book-title {
  white-space: nowrap;
  font-size: 16px;
}

span.book-author {
  white-space: nowrap;
  font-size: 13px;
}

span.fade-title {
  height: 17px;
  width: 45px;
  top: 0;
  right: 0;
  padding: 5px;
  position: absolute;
  background: linear-gradient(to right,rgba(255,255,255,0),rgba(255,255,255,1));
}
