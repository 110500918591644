$mdc-theme-primary: #FFFFFF;
$mdc-theme-on-primary: #757575;

@import '@material/react-top-app-bar/index.scss';
@import '@material/react-text-field/index.scss';
@import '@material/react-icon-button/index.scss';
@import '@material/react-material-icon/index.scss';

.App {
  display: grid;
  grid-template-columns: 1fr;
  background: #e6e6e6;
}

.grid-wrapper {
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 160px);
    grid-gap: 10px;
    justify-content: center;
}

.SearchBar {
  min-height: 50px;
}

.mdc-text-field__input {
  font-size: 1.5rem;
}
