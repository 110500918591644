$material-design-icons-font-directory-path: '~material-design-icons-iconfont/dist/fonts/';

@import '~material-design-icons-iconfont/src/material-design-icons';

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #e6e6e6;
}
