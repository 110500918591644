$mdc-theme-primary: #BF360C;
$mdc-theme-secondary: #004D40;

@import "@material/list/mdc-list";

span.list-download-icon {
  width: 24px;
}

@media screen and (max-width: 450px) {
  .mdc-list-item__text {
    max-width: 325px;
  }
}
